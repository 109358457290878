<template>
  <v-card class="mx-auto mt-5 pa-5" outlined>
    <div class="fields">
      <v-text-field
        outlined
        clearable
        required
        v-model="field.label"
        label="Label"
      ></v-text-field>
      <v-autocomplete
        outlined
        required
        v-model="field.type"
        :items="items"
        label="Option Type"
        v-if="editableType"
      ></v-autocomplete>
      <v-btn @click="cancel" v-if="action !== 'add'" class="mr-3">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!(field.label && field.type)"
        :loading="loading"
        color="primary"
        @click="submit"
      >
        {{ action === 'add' ? 'Add Field' : 'Update Field' }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { optionTypes } from '@/utils/constants'

export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      default: 'add',
    },
    editableType: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        optionTypes().INPUT,
        optionTypes().OPTION,
        optionTypes().CALENDAR,
      ],
      fieldValue: this.field,
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.field)
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
