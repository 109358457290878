<template>
  <div>
    <div
      v-if="!showEditField"
      class="title d-flex align-center justify-space-between"
    >
      <div class="d-flex">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in menuItems" link :key="i">
              <v-list-item-title @click="menuAction(item)">{{
                item
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <h4 class="text-truncate" style="max-width: 400px; width: 100%">
          {{ option.title }}
        </h4>
      </div>
      <v-btn
        class="ma-2"
        @click="
          $emit('showField')
          showField = !showField
        "
        text
        icon
      >
        <v-icon>{{
          showField ? icons.mdiChevronDown : icons.mdiChevronRight
        }}</v-icon>
      </v-btn>
    </div>
    <div
      class="edit-title d-flex align-center justify-space-between ma-5"
      v-if="showEditField"
    >
      <v-text-field
        outlined
        clearable
        required
        hide-details="auto"
        class="mr-2"
        v-model="title"
        label="Onboarding Title"
      ></v-text-field>
      <div class="action-buttons">
        <v-btn
          @click="
            title = option.title
            showEditField = !showEditField
          "
          class="mr-2"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="ml-2"
          :disabled="!title"
          :loading="updateLoading"
          @click="updateTitle()"
        >
          Save
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiChevronRight, mdiChevronDown } from '@mdi/js'
import { onboardingMenu } from '@/utils/constants'

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    updateLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronRight,
        mdiChevronDown,
      },
      showEditField: false,
      title: this.option.title,
      menuItems: [onboardingMenu().EDIT, onboardingMenu().DELETE],
      deleteLoading: false,
      showField: false,
    }
  },
  methods: {
    menuAction(menu) {
      switch (menu) {
        case onboardingMenu().EDIT:
          this.showEditField = !this.showEditField
          break
        case onboardingMenu().DELETE:
          this.deleteOnboardingOption()
          break
      }
    },
    updateTitle() {
      this.$emit('update', Object.assign(this.option, { title: this.title }))
    },
    deleteOnboardingOption() {
      this.$emit('delete', this.option)
    },
    hideField() {
      this.showEditField = false
    },
    onboardingMenu,
  },
}
</script>
