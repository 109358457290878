<template>
  <div>
    <draggable
      :list="option.fields"
      @change="udpateOrder"
      v-show="showFields"
      class="pb-5 px-5"
    >
      <div v-for="(field, i) in option.fields" :key="i">
        <Fields
          :field="field"
          :loading="loading"
          :archiveLoading="archiveLoading"
          :form="form"
          :fieldIndex="i"
          :activeField="activeField"
          :activeOption="activeOption"
          :action="'fields'"
          @update="updateField($event, i)"
          @delete="removeField(field)"
          @onEdit="onEdit(i)"
          @onCancel="onCancel()"
          ref="fields"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import Fields from '@/components/onboarding/Fields'
import draggable from 'vuedraggable'

export default {
  components: {
    Fields,
    draggable,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    archiveLoading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: false,
    },
    activeField: {
      type: Number,
      required: false,
    },
    activeOption: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      showFields: false,
    }
  },
  methods: {
    updateField(field, i) {
      this.$emit('update', { field, ...{ index: i } })
    },
    udpateOrder(oldField) {
      let field = oldField.moved.element
      field.list_order = oldField.moved.newIndex
      this.$emit('update', { field, ...{ index: oldField.moved.newIndex } })
    },
    removeField(field) {
      this.$emit('delete', field)
    },
    updateShowFields() {
      this.showFields = !this.showFields
    },
    onEdit(fieldIndex) {
      this.$emit('onEdit', fieldIndex)
    },
    onCancel() {
      this.$emit('onCancel')
    },
  },
}
</script>
