<template>
  <div>
    <div
      v-if="!showEditField"
      class="field d-flex align-center justify-space-between mt-5"
    >
      <v-text-field
        v-if="
          field.type == optionTypes().INPUT ||
          field.type == checkTypeCasing(optionTypes().INPUT)
        "
        hide-details="auto"
        outlined
        disabled
        readonly
        :label="field.label"
        :error-messages="updateError()"
      ></v-text-field>

      <v-radio-group
        v-if="
          field.type == optionTypes().OPTION ||
          field.type == checkTypeCasing(optionTypes().OPTION)
        "
        hide-details="auto"
        :error-messages="updateError()"
      >
        <v-radio disabled :label="field.label"></v-radio>
      </v-radio-group>

      <v-menu
        v-if="
          field.type == optionTypes().CALENDAR ||
          field.type == checkTypeCasing(optionTypes().CALENDAR)
        "
        ref="menu"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :prepend-icon="icons.mdiCalendar"
            disabled
            readonly
            v-bind="attrs"
            v-on="on"
            :label="field.label"
            :error-messages="updateError()"
          ></v-text-field>
        </template>
        <v-date-picker type="month" no-title scrollable> </v-date-picker>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in menuItems" link :key="i">
            <v-list-item-title @click="menuAction(item)">{{
              item
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <AddEditField
      v-if="showEditField"
      :field="{ ...field }"
      action="edit"
      :loading="loading"
      :editable-type="editableType"
      @cancel="showEditField = !showEditField"
      @submit="updateField"
    />
  </div>
</template>

<script>
import { mdiDotsVertical, mdiCalendar } from '@mdi/js'
import AddEditField from '@/components/onboarding/AddEditField'
import { optionTypes, onboardingMenu } from '@/utils/constants'

export default {
  components: {
    AddEditField,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      default: 'onboarding',
    },
    editableType: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    archiveLoading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: false,
    },
    fieldIndex: {
      type: Number,
      required: false,
    },
    activeField: {
      type: Number,
      required: false,
    },
    activeOption: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiCalendar,
      },
      showEditField: false,
      menuItems: [onboardingMenu().EDIT, onboardingMenu().DELETE],
      optionIndex: null,
    }
  },
  methods: {
    menuAction(menu) {
      switch (menu) {
        case onboardingMenu().EDIT:
          this.onEdit()
          break
        case onboardingMenu().DELETE:
          this.removeField()
          break
      }
    },
    checkTypeCasing(s) {
      return s[0].toLowerCase() + s.slice(1)
    },
    onEdit() {
      this.showEditField = !this.showEditField
      this.$emit('onEdit')
    },
    onCancel() {
      this.showEditField = !this.showEditField
      this.$emit('onCancel')
      this.resetOptionIndex()
    },
    updateField(field) {
      this.optionIndex = this.activeOption
      this.$emit('update', field)
    },
    hideField() {
      this.showEditField = false
    },
    removeField() {
      this.$emit('delete')
    },
    resetOptionIndex() {
      this.optionIndex = null
    },
    updateError() {
      if (this.form) {
        if (this.action === 'onboarding') {
          const err = this.form.$getError(`fields.${this.fieldIndex}.label`)
          return err ? err[0].replace(`.${this.fieldIndex}.`, ' ') : ''
        } else {
          if (
            this.activeField === this.fieldIndex &&
            this.activeOption === this.optionIndex
          ) {
            return this.form.$getError(`label`)
              ? this.form.$getError(`label`)[0]
              : ''
          }
        }
      }
    },
    optionTypes,
    onboardingMenu,
  },
}
</script>
