<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Onboarding</v-toolbar-title
      >
    </v-app-bar>

    <div>
      <v-row>
        <v-col md="6">
          <h2 class="mb-5">Add Onboarding</h2>
          <v-form ref="form" @submit.prevent="mapOnboardingData">
            <v-text-field
              outlined
              clearable
              required
              v-model="form.title"
              label="Onboarding Title"
              :error-messages="form.$getError('title')"
            ></v-text-field>

            <v-card
              outlined
              class="pa-5"
              v-if="fields.length > 0 || form.$getError('fields')"
            >
              <p>Onboarding Fields</p>
              <div
                v-if="
                  form.$getError('fields')
                    ? form.$getError('fields').length > 0
                    : false
                "
              >
                <small
                  v-for="(err, i) in form.$getError('fields')"
                  :key="i"
                  class="red--text text--darken-4"
                  >{{ form.$getError('fields')[i] }}</small
                >
              </div>
              <draggable v-model="fields">
                <div v-for="(field, i) in fields" :key="i">
                  <Fields
                    :field="field"
                    :editable-type="true"
                    :form="form"
                    :fieldIndex="i"
                    ref="fields"
                    @update="updateField($event, i)"
                    @delete="removeField(i)"
                  />
                </div>
              </draggable>
            </v-card>

            <AddEditField
              :editable-type="true"
              :field="field"
              @submit="addField"
            />

            <v-btn
              :loading="onboardingOptionLoading"
              color="primary"
              type="submit"
              class="mt-5 float-right"
            >
              Add Onboarding Options
            </v-btn>
          </v-form>
        </v-col>
        <v-col md="6">
          <h2 class="mb-5">Onboarding Options</h2>
          <draggable :list="onboardingOptions.data" @change="updateOrder">
            <div
              v-for="(option, i) in onboardingOptions.data"
              :key="i"
              class="mt-5"
            >
              <v-card outlined>
                <EditOnboardingTitle
                  :option="option"
                  :updateLoading="onboardingEditTitle"
                  ref="editOnboardingTitle"
                  @update="updateOnboarding($event, i)"
                  @delete="deleteOnboarding($event, i)"
                  @showField="showField(i)"
                />
                <ShowField
                  :option="option"
                  :loading="onboardingUpdateLoading"
                  :archiveLoading="onboardingArchiveLoading"
                  :form="form"
                  :activeField="activeField"
                  :activeOption="activeOption"
                  @update="actionUpdate($event, { option, ...{ index: i } })"
                  @delete="actionRemove($event, option)"
                  @onEdit="onEditField($event, i)"
                  @onCancel="onCancelField()"
                  ref="showField"
                />

                <v-overlay :absolute="true" :value="onboardingItemLoader(i)">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-overlay>
              </v-card>
            </div>
          </draggable>
        </v-col>
      </v-row>
    </div>

    <ConfirmModal
      title="Delete Onboarding"
      message="Do you want to remove this onboarding?"
      v-model="showConfirmModal"
      @cancel="showConfirmModal = false"
      @confirm="removeOption()"
    />
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AddEditField from '@/components/onboarding/AddEditField'
import ShowField from '@/components/onboarding/ShowField'
import EditOnboardingTitle from '@/components/onboarding/EditOnboardingTitle'
import Fields from '@/components/onboarding/Fields'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { optionTypes } from '@/utils/constants'
import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'OnBoarding',
  components: {
    AppBarNavIcon,
    AddEditField,
    Fields,
    ShowField,
    EditOnboardingTitle,
    ConfirmModal,
    draggable,
  },
  computed: {
    ...mapState('onboarding', {
      form: (state) => state.onboardingOptionsForm,
      onboardingOptions: (state) => state.onboardingOptions,
      onboardingUpdateLoading: (state) => state.onboardingUpdateLoading,
      onboardingArchiveLoading: (state) => state.onboardingArchiveLoading,
      onboardingEditTitle: (state) => state.onboardingEditTitle,
      onboardingOptionLoading: (state) => state.onboardingOptionLoading,
    }),
  },
  data() {
    return {
      items: [
        optionTypes().INPUT,
        optionTypes().OPTION,
        optionTypes().CALENDAR,
      ],
      fields: [],
      field: {
        label: null,
        type: null,
      },
      activeField: null,
      activeOption: null,
      activeOnboardingLoading: null,
      showConfirmModal: false,
      optionToRemove: {},
    }
  },
  mounted() {
    this.getOnboardingOptions()
  },
  methods: {
    ...mapActions({
      addOnboardingOptions: 'onboarding/addOnboardingOptions',
      getOnboardingOptions: 'onboarding/getOnboardingOptions',
      updateOptionFields: 'onboarding/updateOptionFields',
      removeOptionFields: 'onboarding/removeOptionFields',
      updateOnboardingOptions: 'onboarding/updateOnboardingOptions',
      archiveOnboardingOption: 'onboarding/archiveOnboardingOption',
    }),
    addField() {
      this.fields.push(this.field)
      this.clearField()
      this.form.$clearErrors()
    },
    clearField() {
      this.field = {
        label: null,
        type: null,
      }
    },
    removeField(index) {
      if (index > -1) {
        this.fields.splice(index, 1)
      }
    },
    mapOnboardingData() {
      this.form.fields = this.fields.map((obj, i) => ({
        ...obj,
        list_order: i + 1,
      }))
      this.addOnboardingOptions(this.form).then(() => {
        if (!this.form.$hasErrors()) {
          this.fields = []
          this.clearField()
        }
      })
    },
    updateField(data, index) {
      this.fields[index] = Object.assign(this.fields[index], data)
      this.form.$clearErrors()
      this.$refs.fields[index].hideField()
    },
    actionUpdate(field, data) {
      this.updateOptionFields({
        ...{ field: field.field },
        ...{ optionId: data.option.id },
      }).then(() => {
        this.$refs.showField[data.index].$refs.fields[field.index].hideField()
      })
    },
    actionRemove(field, option) {
      this.removeOptionFields({ field, ...{ optionId: option.id } })
    },
    removeOption() {
      this.activeOnboardingLoading = this.optionToRemove.index
      this.archiveOnboardingOption(this.optionToRemove.data).then(() => {
        this.activeOnboardingLoading = null
        this.showConfirmModal = false
      })
    },
    updateOrder(data) {
      data.moved.element.list_order = data.moved.newIndex + 1
      this.updateOnboardingOptions(data.moved.element)
    },
    updateOnboarding(data, index) {
      this.updateOnboardingOptions(data).then(() => {
        this.$refs.editOnboardingTitle[index].hideField()
      })
    },
    onboardingItemLoader(index) {
      return index === this.activeOnboardingLoading
    },
    deleteOnboarding(data, index) {
      this.optionToRemove = { data, index }
      this.showConfirmModal = true
    },
    showField(i) {
      this.$refs.showField[i].updateShowFields()
    },
    onEditField(fieldIndex, onboardingIndex) {
      this.activeField = fieldIndex
      this.activeOption = onboardingIndex
    },
    onCancelField() {
      this.activeField = null
    },
  },
}
</script>
