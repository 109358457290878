const constants = {
  optionTypes: {
    INPUT: 'Input',
    OPTION: 'Option',
    CALENDAR: 'Calendar',
  },
  onboardingMenu: {
    EDIT: 'Edit',
    DELETE: 'Delete',
  },
  programMenu: {
    EDIT: 'Edit',
    DELETE: 'Delete',
  },
  programExerciseDayMenu: {
    EDIT: 'Edit',
    DELETE: 'Delete',
    DUPLICATE: 'Duplicate',
  },
}

export function optionTypes() {
  return constants.optionTypes
}

export function onboardingMenu() {
  return constants.onboardingMenu
}

export function programMenu() {
  return constants.onboardingMenu
}

export function programExerciseDayMenu() {
  return constants.programExerciseDayMenu
}
